@font-face {
  font-family: 'DM7R';
  src: local('DM7R'), url('./fonts/DSEG14Classic-Regular.woff') format('woff');
}

html, body, #root, #root>div, .full {
  height: 100%
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}